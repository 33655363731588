import { useEffect, useMemo, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Avatar, Button, Collapse, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { onLogout } from "../../redux/auth/actions";
import LogoutConfirmationDialog from "../LogoutConfirmation";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import NotificationDropdown from "./NotificationDropDown";
import Confirm from "../../components/confirmModal";
// import orgIcon from "/assets/icons/menuIcons/city-01.svg"

const drawerWidth = 240;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ED2A4D",
    color: "white",
    boxShadow: theme.shadows[20],
    fontSize: 15,
    padding: "10px",
    width: "130px",
    textAlign: "center",
  },
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuBar = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [menuItemOpen, setMenuItemOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);
  const [fromShotDetail, setFromShotDetail] = useState(false);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(false);

  const dispatch = useDispatch();
  const { userData } = useSelector((data) => data.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname && location?.pathname?.split("/")[1]) {
      setSelectedItem(location?.pathname?.split("/")[1]);
    }
  }, [location?.pathname]);
  const settings = [
    { name: "Account", path: "profile-managment" },
    userData?.role !== "Client Admin" && {
      name: "Dashboard",
      path: "dashboard",
    }, // Dashboard only for non-Client Admins
    { name: "Logout", path: "logout" },
  ].filter(Boolean);
  const pages = [
    {
      name: "Slenky Home Page",
      path: "https://www.slenky.com/",
      icon: "/assets/icons/menuIcons/home.svg",
      slug: "slenky_home_page",
    },
    userData?.role !== "Client Admin"
      ? {
          name: "Home",
          path: "dashboard",
          icon: "/assets/icons/menuIcons/home.svg",
          slug: "slenky_home_page",
        }
      : {},
    {
      name: "Organisations",
      path: "organisations",
      icon: "/assets/icons/menuIcons/organizations.svg",
      slug: "organization_list",
    },
    {
      name: userData?.role === "Client Admin" ? "My Hub" : "Hubs",
      path: "hubs",
      icon: "/assets/icons/menuIcons/hubs.svg",
      slug: "hub_list",
    },
    {
      name: "Shots",
      path: "shots",
      icon: "/assets/icons/menuIcons/shots.svg",
      slug: "shot_list",
    },
    {
      name: "CMS",
      path: "",
      icon: "/assets/icons/menuIcons/cms.svg",
      // slug: "cms_list",
      children: [
        {
          name: "Posts",
          path: "posts",
          icon: "/assets/icons/menuIcons/categories.svg",
          slug: "post_list",
        },
        {
          name: "Pages",
          path: "pages",
          icon: "/assets/icons/menuIcons/products.svg",
          slug: "page_list",
        },
      ],
    },
    {
      name: "Reports",
      path: "reports",
      icon: "/assets/icons/menuIcons/user-management.svg",
      slug: "report_view",
    },
    {
      name: "Billing",
      path: "billing",
      icon: "/assets/icons/menuIcons/reports.svg",
      slug: "invoice_list",
    },
    {
      name: "Subscriptions",
      path: "",
      icon: "/assets/icons/menuIcons/featured.svg",
      slug: "organization_subscriptions",
    },
    {
      name: "User Management",
      path: "user-management",
      icon: "/assets/icons/menuIcons/user.svg",
      slug: "user_management_list",
    },
    {
      name: "Configurations",
      path: "",
      icon: "/assets/icons/menuIcons/configurations.svg",
      children: [
        {
          name: "Organisation Type",
          path: "categories",
          icon: "/assets/icons/menuIcons/categories.svg",
          slug: "category_list",
        },
        {
          name: "Products",
          path: "products",
          icon: "/assets/icons/menuIcons/products.svg",
          slug: "subscription_list",
        },
        {
          name: "Activities",
          path: "activities",
          icon: "/assets/icons/menuIcons/activities.svg",
          slug: "activity_list",
        },
        // {
        //     name: 'Topics',
        //     path: 'topics',
        //     icon:("/assets/icons/menuIcons/city-01.svg")
        // },
        {
          name: "Passions",
          path: "passions",
          icon: "/assets/icons/menuIcons/passions.svg",
          slug: "passion_list",
        },
        {
          name: "Featured Brands",
          path: "featured-brands",
          icon: "/assets/icons/menuIcons/featured.svg",
          slug: "",
        },
        {
          name: "Featured",
          path: "featured",
          icon: "/assets/icons/menuIcons/featured.svg",
          slug: "featured",
        },
        {
          name: "General settings",
          path: "settings",
          icon: "/assets/icons/menuIcons/settings.svg",
          slug: "setting_list",
        },
      ],
    },
  ];

  const getDefaultImageOnRole = () => {
    const slenkyLogo = "/assets/images/slenky-logo.svg";
    const defaultURL = "/assets/images/defaultProfile.png";
    const { role, organisation } = userData;

    if (role === "Slenky Admin" || role === "Slenky Manager") {
      return slenkyLogo;
    }

    if (
      organisation &&
      (role === "Client Admin" || role === "Client Manager")
    ) {
      return organisation.logo;
    }
    return defaultURL;
  };

  const filterMenuItems = (menuItems, permissions) => {
    // Ensure permissions is an array
    if (!Array.isArray(permissions)) {
      return [];
    }

    return menuItems
      .map((item) => {
        if (item.children) {
          const filteredChildren = filterMenuItems(item.children, permissions);
          if (filteredChildren.length > 0) {
            return { ...item, children: filteredChildren };
          }
        } else if (item?.slug && permissions.includes(item.slug)) {
          return item;
        }
        return null;
      })
      .filter((item) => item !== null);
  };

  const filteredPages = useMemo(
    () => filterMenuItems(pages, userData?.permissions),
    [pages, userData?.permissions]
  );

  const onUserMenuClick = (path) => {
    if (path === "logout") {
      setOpenLogoutConfirmation(true);
    } else navigate(`/${path}`);
  };

  const handleClose = () => {
    setOpenLogoutConfirmation(false);
  };
  const handleLogout = () => {
    setOpenLogoutConfirmation(false);
    dispatch(onLogout(navigate));
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleExpand = (e) => {
    e.stopPropagation();
    // setSelectedItem(path);
    setOpen(true);
    setMenuItemOpen((menuItemOpen) => !menuItemOpen);
  };
  const handleItemClick = (path) => {
    if (path) {
      if (path === "https://www.slenky.com/") {
        setSelectedItem(path);
        window.open(path, "_blank");
        setOpen(true);
      } else if (window.location.pathname === "/shots/details") {
        setFromShotDetail(path);
      } else {
        setSelectedItem(path);
        navigate(`/${path}`);
        setOpen(true);
      }
    } else {
      setOpen(true);
      setMenuItemOpen((menuItemOpen) => !menuItemOpen); // Toggle the open state of the drawer
    }
  };

  const closeModalSucc = (path) => {
    setSelectedItem(path);
    navigate(`/${path}`);
    setOpen(true);
    setFromShotDetail(false);
  };

  console.log("loca", location.pathname);

  const renderMenuItems = (items) => {
    return items?.map((item) => {
      const uniqueKey = item?.children?.path || item?.children?.name; // Use a unique identifier from the item

      if (item.children) {
        return (
          <div key={uniqueKey}>
            <LightTooltip title={item.name} placement="right" arrow>
              <ListItem button onClick={() => handleItemClick(item.path)}>
                <ListItemIcon sx={{ minWidth: "2rem" }}>
                  <img src={item.icon} alt="icon" />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={(e) => handleExpand(e, uniqueKey)}
                >
                  {menuItemOpen ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItem>
            </LightTooltip>
            <Collapse in={menuItemOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ paddingLeft: "2rem" }}>
                {renderMenuItems(item.children)}
              </List>
            </Collapse>
          </div>
        );
      } else {
        return (
          <LightTooltip
            key={uniqueKey}
            title={item.name}
            placement="right"
            arrow
          >
            <ListItem
              button
              onClick={() => handleItemClick(item.path)}
              selected={selectedItem === item.path}
              className={
                location?.pathname?.replace("/", "") === item?.path
                  ? //  ||
                    // selectedItem === item.path
                    "menu-btn-active"
                  : ""
              }
            >
              <ListItemIcon sx={{ minWidth: "2rem" }}>
                <img src={item.icon} alt="icon" />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          </LightTooltip>
        );
      }
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Confirm
        isActive={fromShotDetail}
        handleClose={() => setFromShotDetail(false)}
        onDelete={closeModalSucc}
        alertMessage="Changes that you made may not be saved."
        alertHeadder="Are you sure you want to leave this page?"
        cancelButton="No"
        okButton={"yes"}
      />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div"> */}
          <img src="/assets/images/slenky-logo-big.svg" width={110} />
          {/* SLENKY */}
          {/* </Typography> */}

          <Box sx={{ flexGrow: 0, marginRight: 0, marginLeft: "auto" }}>
            {/* <Button
              sx={{ marginRight: 1, marginLeft: "auto" }}
              className="btn-white"
              variant="primary"
            // onClick={() => setOpenModal(true)}
            >
              New Shot
            </Button>{" "} */}
            <NotificationDropdown />
            <Tooltip title="Open settings">
              <IconButton
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                sx={{
                  p: 0,
                  borderRadius: "50%",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#80808087",
                  },
                }}
              >
                <Avatar
                  alt="Profile Picture"
                  src={
                    userData.avatar ? userData.avatar : getDefaultImageOnRole()
                  }
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings?.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Button
                    textAlign="center"
                    onClick={() => onUserMenuClick(setting?.path)}
                    fullWidth
                  >
                    {setting?.name}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} className="custom-scrollbar">
        <DrawerHeader sx={{ background: "#ED2A4D" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          className="custom-scrollbar"
          sx={{ overflowY: "auto", overflowX: "hidden", padding: "14px 0px" }}
        >
          {renderMenuItems(filteredPages)}
        </List>
        <Divider />
      </Drawer>

      <Box component="main">
        {/* <DrawerHeader /> */}
        {children}
      </Box>
      <LogoutConfirmationDialog
        open={openLogoutConfirmation}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
    </Box>
  );
};

export default MenuBar;

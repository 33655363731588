import {
  deleteProfileApi,
  forgotPassword,
  getMyPermissionsAPI,
  loginUser,
  registerUser,
  resendOTPApi,
  resetPasswordApi,
  updatePasswordApi,
  updateProfileInfoApi,
  verifyOtp,
} from "../../apis/services/authApi";
import CommonToast from "../../components/toastContainer";
import { getCommonDropDownValues } from "../common/action";
import { updatePaymentState } from "../payment/actions";

export const ActionTypes = {
  LOGIN_PENDING: "LOGIN_PENDING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT_PENDING: "LOGOUT_PENDING",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_PENDING: "FORGOT_PASSWORD_PENDING",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  SIGN_UP: "SIGN_UP",
  SIGN_UP_PENDING: "SIGN_UP_PENDING",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_ERROR: "SIGN_UP_ERROR",
  VERIFY_OTP_LOADING: "VERIFY_OTP_LOADING",
  VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  UPDATE_PROFILE_INFO_LOADING: "UPDATE_PROFILE_INFO_LOADING",
  UPDATE_PROFILE_INFO_SUCCESS: "UPDATE_PROFILE_INFO_SUCCESS",
  UPDATE_PROFILE_INFO_ERROR: "UPDATE_PROFILE_INFO_ERROR",
  UPDATE_PASSWORD_LOADING: "UPDATE_PASSWORD_LOADING",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR: "UPDATE_PASSWORD_ERROR",
  RESET_PASSWORD_LOADING: "RESET_PASSWORD_LOADING",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
  RESEND_OTP_LOADING: "RESEND_OTP_LOADING",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_ERROR: "RESEND_OTP_ERROR",
  DELETE_PROFILE_LOADING: "DELETE_PROFILE_LOADING",
  DELETE_PROFILE_SUCCESS: "DELETE_PROFILE_SUCCESS",
  DELETE_PROFILE_ERROR: "DELETE_PROFILE_ERROR",
  GET_MY_PERMISSIONS_LOADING: "GET_MY_PERMISSIONS_LOADING",
  GET_MY_PERMISSIONS_SUCCESS: "GET_MY_PERMISSIONS_SUCCESS",
  GET_MY_PERMISSIONS_ERROR: "GET_MY_PERMISSIONS_ERROR",
};

export const handleLoginReq = (data, navigate, setSubmitting) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_PENDING });
    // navigate('/dashboard')LOGIN_SUCCESS
    loginUser(data)
      .then((response) => {
        if (response.data?.token) {
          const userData = response.data?.user;
          const subscriptionData =
            response.data?.organisation_subscription_data;
          userData.token = response.data?.token;
          userData.permissions = response?.data?.permissions;
          dispatch({
            type: ActionTypes.LOGIN_SUCCESS,
            payload: userData,
          });
          dispatch(updatePaymentState(subscriptionData?.status));
          dispatch(getCommonDropDownValues());
          localStorage.setItem("token", userData?.token);
          CommonToast.notify("success", response?.data?.message);
          if (userData?.has_updated_profile === 0) {
            if (
              userData?.role === "Slenky Admin" ||
              userData?.role === "Client Admin"
            ) {
              navigate("hubs");
            } else {
              navigate("shots");
            }
          } else {
            navigate("/dashboard");
          }
          setSubmitting(false);
        } else
          CommonToast.notify(
            "error",
            response?.data?.message || "error logging in"
          );
        setSubmitting(false);
        // toast('Invalid Password', 'error')

        // dispatch({ type: ActionTypes.LOADER_STOP_AUTH })
      })
      .catch((error) => {
        setSubmitting(false);
        CommonToast.notify(
          "error",
          error?.response?.data?.message || "error logging in"
        );

        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.LOGIN_ERROR });
      });
  };
};
export const handleRegisterReq = (data, navigate, setSubmitting) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SIGN_UP_PENDING });
    registerUser(data)
      .then((response) => {
        const userEmail = response.data?.user?.email;
        // userData.token = response.data?.token;
        dispatch({
          type: ActionTypes.SIGN_UP_SUCCESS,
          payload: userEmail,
        });
        CommonToast.notify("success", "Registered Successfully");
        // localStorage.setItem("token", userData?.token);
        navigate("/verify-otp");
        setSubmitting(false);

        // toast('Invalid Password', 'error')
        dispatch({ type: ActionTypes.SIGN_UP_ERROR });
      })
      .catch((error) => {
        setSubmitting(false);
        CommonToast.notify("error", error?.response?.data?.message);
        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.LOGIN_ERROR });
      });
  };
};

export const onVerifyOTP = (data, navigate, setIsSubmitting) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.VERIFY_OTP_LOADING });
    verifyOtp(data)
      .then((response) => {
        const { is_forgot_password, otp } = data;
        dispatch({
          type: ActionTypes.VERIFY_OTP_SUCCESS,
          payload: otp,
        });

        if (is_forgot_password === "1") {
          navigate("/reset-password");
        } else {
          navigate("/");
        }
        setIsSubmitting(false);
        CommonToast.notify("success", "OTP Verified Successfully");
      })
      .catch((error) => {
        setIsSubmitting(false);
        CommonToast.notify("error", error?.response?.data?.message);
        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.VERIFY_OTP_ERROR });
      });
  };
};

export const onLogout = (navigate) => {
  return (dispatch) => {
    dispatch({ type: "LOGOUT_SUCCESS", payload: {} });
    dispatch({ type: "CLEAR_STORE" });
    localStorage.removeItem("token");
    // navigate("/");
    CommonToast.notify("success", "Logged out successfully");
  };
};

export const handleForgotPasswordReq = (data, navigate, setSubmitting) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.FORGOT_PASSWORD_PENDING });
    forgotPassword(data)
      .then((response) => {
        dispatch({
          type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
          payload: data?.email,
        });
        CommonToast.notify("success", response?.data?.message);
        navigate("/verify-otp");
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        CommonToast.notify("error", error?.response?.data?.message);
        dispatch({ type: ActionTypes.FORGOT_PASSWORD_ERROR });
      });
  };
};

export const getPermssions = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_MY_PERMISSIONS_LOADING });
    getMyPermissionsAPI()
      .then((response) => {
        const userPermissions = response.data?.data?.permissions;
        // userData.token = response.data?.token;
        dispatch({
          type: ActionTypes.GET_MY_PERMISSIONS_SUCCESS,
          payload: userPermissions,
        });
        // CommonToast.notify("success", "Registered Successfully");
        // localStorage.setItem("token", userData?.token);

        // dispatch({ type: ActionTypes.SIGN_UP_ERROR });
      })
      .catch((error) => {
        CommonToast.notify("error", error?.response?.data?.message);
        // toast(error.response.data.detail, 'error')
        dispatch({ type: ActionTypes.GET_MY_PERMISSIONS_ERROR });
      });
  };
};

export const updateProfileInfo = (data, resetForm) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_PROFILE_INFO_LOADING });
    updateProfileInfoApi(data)
      .then((response) => {
        const updatedProfile = response?.data?.user;
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_PROFILE_INFO_SUCCESS,
          payload: updatedProfile,
        });
        resetForm();
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        dispatch({ type: ActionTypes.UPDATE_PROFILE_INFO_ERROR });
      });
  };
};

export const deleteProfile = (navigate, setCancelDelete) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.DELETE_PROFILE_LOADING });
    deleteProfileApi()
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        dispatch({ type: ActionTypes.DELETE_PROFILE_SUCCESS });
        dispatch({ type: "CLEAR_STORE" });
        localStorage.removeItem("token");
        navigate("/");
        setCancelDelete(false);
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );
        dispatch({ type: ActionTypes.DELETE_PROFILE_ERROR });
        setCancelDelete(false);
      });
  };
};

export const updatePassword = (data, resetForm) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.UPDATE_PASSWORD_LOADING });
    updatePasswordApi(data)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        dispatch({
          type: ActionTypes.UPDATE_PASSWORD_SUCCESS,
        });
        resetForm();
      })
      .catch((error) => {
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        dispatch({ type: ActionTypes.UPDATE_PASSWORD_ERROR });
      });
  };
};

export const resetPassword = (data, navigate, setSubmitting) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESET_PASSWORD_LOADING });
    resetPasswordApi(data)
      .then((response) => {
        CommonToast.notify("success", response?.data?.message);
        navigate("/");
        dispatch({
          type: ActionTypes.RESET_PASSWORD_SUCCESS,
        });
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        CommonToast.notify(
          "error",
          error.response?.data.message || error.message
        );

        dispatch({ type: ActionTypes.RESET_PASSWORD_ERROR });
      });
  };
};

export const resendOTP = (data) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESEND_OTP_LOADING });
    resendOTPApi(data)
      .then((response) => {
        dispatch({
          type: ActionTypes.RESEND_OTP_SUCCESS,
          payload: {},
        });
        CommonToast.notify("success", response?.data?.message);
      })
      .catch((error) => {
        CommonToast.notify("error", error?.response?.data?.message);
        dispatch({ type: ActionTypes.RESEND_OTP_ERROR });
      });
  };
};
